import React from 'react';

//COMPONENTS
import Profile from '../components/profile.js';

// IMAGES
import Feature1 from '../pictures/Features/Feature1.jpg';
import Feature2 from '../pictures/Features/Feature2.jpg';
import Feature3 from '../pictures/Features/Feature3.png';
import Feature4 from '../pictures/Features/Feature4.png';

const Home = () => {
  return(
<div className="mainDiv content">
<p className="HomePageTitle">Featured Stories</p>
<div className="flex-container-home">
<div className="item-home">
<Profile title = "Lab-Created Nano Aerosols Could Improve Climate Models" url = "/labCreatedNanoAerosols" image={Feature1}/>
</div>
<div className="item-home">
<Profile title = "Becoming the Noise: A Visit to One of the Quietest Places on Earth" url = "/becomingTheNoise" image={Feature2}/>
</div>
<div className="item-home">
<Profile title = "Online Hatred and Extremism: A Physicist's Take" url = "/onlineHatredAndExtremism"image={Feature3}/>
</div>
<div className="item-home">
<Profile title = 'The Surprising Behavior of "Whirlpools" of Light' url = "/theSurprisingBehaviorWhirlpools"image={Feature4}/>
</div>
</div>
</div>
  );
}

export default Home
