import React from 'react';

const Portfolio = () => {
  return(
    <div class="mainDiv content">
    <p className='pageTitle'> Portfolio </p>
    <p className="introPara hide">This collection is a small subset of Kendra’s 150+ published articles. To browse the most recent stories, please visit Kendra Redmond Stories on <a className="emailHyper" href = "https://www.facebook.com/KRStories/" target="_blank">Facebook</a>. For a list of stories on a particular subject area, please email <a className="emailHyper" id="mailto" href="mailto:KRedmondStories@gmail.com" target="_blank"> KRedmondStories@gmail.com.</a></p>
    <br/>
    <p className="portfolioHeader">FOR GENERAL AUDIENCES</p>

    <p className="wTitle mobileFont"><a href="https://www.sigmapisigma.org/sigmapisigma/radiations/spring/2017/cosmic-treasure-hunt-join-citizen-science-project-searching" target="_blank">Cosmic Treasure Hunt</a></p>
    <p className="wLink">Published in Radiations magazine</p>
    <p className="wDescription hide">Calling all readers: Join the hunt for a possible “Planet Nine” via your computer!</p>
    <br/>

    <p className="wTitle mobileFont"><a href="http://physicsbuzz.physicscentral.com/2018/03/helping-soldiers-disappear-in-burst-of.html" target="_blank">Helping Soldiers Disappear in a Burst of Smoke</a></p>
    <p className="wLink">Published on Physics Buzz</p>
    <p className="wDescription hide">In combat, a well-placed burst of smoke can be salvation. This story highlights work on a smoke grenade for keeping soldiers hidden from enemy eyes.</p>
    <br/>

    <p className="wTitle mobileFont"><a href="https://twincities.citymomsblog.com/after-dinner-science/" target="_blank">After Dinner Science</a></p>
    <p className="wLink">Published on Twin Cities Moms Blog</p>
    <p className="wDescription hide">This post features fun, simple, and tasty science activities for kids of all ages.</p>
    <br/>
    <hr className="hrStyle"/>
    <p className="portfolioHeader">FOR SCIENCE ENTHUSIASTS</p>

    <p className="wTitle mobileFont"><a href="http://physicsbuzz.physicscentral.com/2017/05/gravity-caught-stretching-quantum.html" target="_blank">Gravity Caught Stretching Quantum Objects</a></p>
    <p className="wLink">Published on Physics Buzz</p>
    <p className="wDescription hide">In the experiment featured in this story, scientists used an extremely sensitive tool to observe the tidal forces of gravity acting on a quantum system.</p>
    <br/>

    <p className="wTitle mobileFont"><a href="http://physicsbuzz.physicscentral.com/2017/11/flash-thunderstorms-intensify-over-busy.html" target="_blank">Thunderstorms Intensify Over Busy Shipping Lanes</a></p>
    <p className="wLink">Published on Physics Buzz</p>
    <p className="wDescription hide">In the fascinating and eye-opening research reported here, scientists explore the connection between ship exhaust and storm intensity.</p>
    <br/>

    <p className="wTitle mobileFont"><a href="http://physicsbuzz.physicscentral.com/2017/08/measuring-very-real-pressure-of-virtual.html" target="_blank">Measuring the Very Real Pressure of Virtual Photons</a></p>
    <p className="wLink">Published on Physics Buzz</p>
    <p className="wDescription hide">Particles of light can form a “wind” that pushes on a mirror. This post explains how there can still be "wind" even if photons aren't present.</p>
    <br/>
    <hr className="hrStyle"/>
    <p className="portfolioHeader">FOR RESEARCHERS</p>

    <p className="wTitle mobileFont"><a href="https://www.cambridge.org/core/journals/mrs-bulletin/news/glassy-graphene-shows-promise-for-flexible-transparent-electronics" target="_blank">Glassy graphene shows promise for flexible, transparent electronics</a></p>
    <p className="wLink">Published in MRS News</p>
    <p className="wDescription hide">This story describes a new thin film that combines the attractive properties of glassy carbon and graphene and its possible applications.</p>
    <br/>

    <p className="wTitle mobileFont"><a href="https://www.cambridge.org/core/journals/mrs-bulletin/news/surprising-force-patterns-and-deformation-waves-discovered-at-tissue-boundaries" target="_blank">Surprising force patterns and deformation waves discovered at tissue boundaries</a></p>
    <p className="wLink">Published in MRS News</p>
    <p className="wDescription hide">The surprising discovery highlighted in this story sheds light on the formation of boundaries between healthy and diseased tissue and tissues with different functions.</p>
    <br/>

    <p className="wTitle mobileFont"><a href="https://www.cambridge.org/core/journals/mrs-bulletin/article/severe-funding-cuts-threaten-the-future-of-research-in-brazil/064BF36A2D56DD3E1F518E321CE9ED20" target="_blank">Severe funding cuts threaten the future of research in Brazil</a></p>
    <p className="wLink">Published in The MRS Bulletin</p>
    <p className="wDescription hide">This news article draws attention to dramatic cuts in Brazil's federal funding for science and the impact on the materials science community.</p>

    </div>

);
    }

    export default Portfolio;
