import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import Header from './components/header.js';
import Footer from './components/footer.js';
import Main from './components/main.js';

import Style from './css/style.css';

function App() {
  return (
<div>
<Router>
<div className="App">
  <Header/>
<Main/>
  <Footer/>
  </div>
</Router>
</div>
  );
}

export default App;
