import React from 'react';
import {Link} from 'react-router-dom';
import { NavLink } from 'react-router-dom'

import logo from '../pictures/Logo/Logo310x78.png';
import vector from '../pictures/Vector/headerVector.png';

const Header = () => {
return(
  <header>
    <div className="flex-container">
      <div className="flex-item">
        <a href="/home"><img className="logoImage" src={logo} alt="logo"/></a>
      </div>
      <div className="flex-item">
        <img className="vectorImage" src={vector} alt="vector"/>
        <div className="headerText flex-item-drop">
        <p><span className="line1">Sparking curiosity, celebrating progress,</span> <br/>
        <span className="line2">and highlighting our quest to</span> <br/>
        <span className="line3">understand the universe...</span></p>
        </div>
      </div>
    </div>

      <ul className="navigation">
        <li><NavLink to="/home" activeStyle={{color: "#3fb798"}}>Home</NavLink></li>
        <li><NavLink to="/about" activeStyle={{color: "#3fb798"}}>About</NavLink></li>
        <li><NavLink to="/portfolio" activeStyle={{color: "#3fb798"}}>Portfolio</NavLink></li>
      </ul>

  </header>
);
}



export default Header
