import React from 'react';
import Fragment from 'react-dot-fragment';

const Profile = props => {
return(

<Fragment>
    <a className="link" href={props.url}>
      <img className="profileImage" src={props.image} alt={props.image}/>
      <p>{props.title}</p>
    </a>
</Fragment>
      );

}

export default Profile
