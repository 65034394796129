import React from 'react';
import Fragment from 'react-dot-fragment';

import {Route} from 'react-router-dom';

import Home from '../pages/home.js';
import Portfolio from '../pages/portfolio.js';
import About from '../pages/about.js';
import Feature1 from '../pages/feature1.js';
import Feature2 from '../pages/feature2.js';
import Feature3 from '../pages/feature3.js';
import Feature4 from '../pages/feature4.js';

const Main = () => {
return(
<Fragment>
  <Route exact path = '/' component = {Home}/>
  <Route exact path = '/home' component = {Home}/>
  <Route path = '/portfolio' component = {Portfolio}/>
  <Route path = '/about' component = {About}/>
  <Route path = '/labCreatedNanoAerosols' component = {Feature1}/>
  <Route path = '/becomingTheNoise' component = {Feature2}/>
  <Route path = '/onlineHatredAndExtremism' component = {Feature3}/>
  <Route path = '/theSurprisingBehaviorWhirlpools' component = {Feature4}/>
</Fragment>
);
}

export default Main
