import React from 'react';

const Footer = () => {
return(
<footer className="footer footerStyle">
<a href="https://www.facebook.com/KRStories/" target="_blank">
    <i className="footerIcon fab fa-facebook-square fa-3x"></i>
  </a>
  <a href="https://www.linkedin.com/in/krstories/" target="_blank">
    <i className="footerIcon fab fa-linkedin fa-3x"></i>
  </a>
  <div>©2019, Kendra Redmond Stories. All Rights Reserved.</div>
</footer>
);
}

export default Footer
