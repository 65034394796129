exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./about.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./feature.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./fonts.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./footer.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./header.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./home.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./navbar.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./portfolio.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./profile.css"), "");

// module
exports.push([module.id, "html {\n  height: 100%;\n}\n.App {\n  display: flex;\n  flex-direction: column;\n  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */\n}\n.content {\n  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */\n}\n\n* {\n  box-sizing: border-box;\n}\nbody {\n  margin: 0;\n  font: 16px Sans-Serif;\n}\nh1 {\n  margin: 0 0 20px 0;\n}\np {\n  margin: 0 0 20px 0;\n}\n\n.mainDiv{\n  padding-top: 30px;\n  padding-left: 50px;\n  padding-right:50px;\n}\n\n@media (max-width: 800px) {\n  .mainDiv{\n    padding-top: 30px;\n    padding-left: 25px;\n    padding-right:25px;\n  }\n}\n", ""]);

// exports
