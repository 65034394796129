import React from 'react';

// IMAGES
import Feature2Pic from '../pictures/Features/Feature2.jpg';

const Feature2 = () => {
  return <div className="mainDiv content">
  <div className="featureImg">
  <img  src={Feature2Pic} alt="" width="300" height="300"/>
  <p>Inside the anechoic chamber of Orfield Labs. Credit: Kendra Redmond.</p>
  </div>

<p className="articleTitle">Becoming the Noise: A Visit to One of the Quietest Places on Earth</p>
<p className="byAuthor">by Kendra Redmond</p>

<div className="mobileImg">
<img  src={Feature2Pic} alt="" width="300" height="300"/>
<p>Inside the anechoic chamber of Orfield Labs. Credit: Kendra Redmond.</p>
</div>

<p>My ultra-smooth gel pen made a distracting and mildly irritating sound that I can only describe as scratchy with each stroke. I became acutely aware of the process involved in forming each letter. I flipped a page in my memo pad to make room for more notes, but the loud, prolonged crinkling of the page only left me more distracted and further behind.</p>
<p>I was standing in a small room whose surfaces were completely covered on all six sides by sound-absorbing wedges more than 3 feet deep. The wire mesh floor—suspended by springs to dampen outside vibrations—was constructed to hold people and equipment but interfere as little as possible with the absorbers covering a sunken floor beneath. This room is arguably the quietest place on Earth. In here, you can hear a pin falling on carpet.</p>
<p>Until recently, I had no idea that the skyline of Minneapolis, Minnesota was a gateway to silence. But that’s where I headed earlier this month, to experience the place where some say you can hear the blood rushing through your veins. Orfield Laboratories Inc. is a quiet, unassuming building covered in ivy. It sits on the southeast side of Minneapolis, across the street from a park and next to a store with a big neon “LIQUOR” sign.</p>
<p>Pieces of modern art from president and founder Steve Orfield’s personal collection are on display in the small lobby, and there’s a comfortably crowded display in one corner. Orfield started my tour there, pointing out a framed album cover of Bob Dylan’s Blood on the Tracks—portions of which were recorded here in 1974, when the building was home to recording studio Sound80.</p>
<p>Sound80 pioneered digital music recording and is recognized by Guinness World Records as the “oldest multi-track digital recording studio” in the world. Prince recorded here too, and Orfield tells me that he sat in on a 1976 recording session with Cat Stevens: at the time, Sound80 was a client of Orfield Labs, contracting their services for a studio redesign.</p>
<p>Propped against a display table is a framed poster for A Quiet Place, a recently released major motion picture in which silence plays a key role. The movie’s sound editors spent a few days here earlier this year, experiencing the power of silence firsthand. The display is completed by books, articles, and other memorabilia highlighting the history of this unique place and the work done here.</p>
<p>Most of the public interest in the lab stems from its anechoic (echo-free) chamber: In 2005 and 2013, Guinness World Records recognized the chamber as “the quietest place on Earth.” That distinction is now held by an anechoic chamber at Microsoft’s headquarters, but Orfield plans to challenge that record in the near future. The two chambers were measured differently, and if compared apples-to-apples it’s not clear who would win.</p>
<p>Anechoic chambers are so quiet for two primary reasons: they are insulated from outside noise, and they absorb the reflections of sound produced inside of the room. Anechoic chambers are also constructed so that lights, air flow, and other building elements are as silent as possible.</p>
<p>People chatting, espresso machines whizzing, dishwashers humming—these noisy sources transmit sound by vibrating nearby air molecules, creating compressions and rarefactions that travel through the air and into the ears of people nearby, where they are detected and interpreted. To keep such noises out, the chamber is completely isolated from the rest of the building: there’s a physical gap between the wall of the inner chamber and the wall of the room it sits inside.</p>
<p>To reduce the noise inside the room even more, the surfaces inside anechoic chambers are covered in a sound-absorbing material. This is because, like light, sound is reflected, transmitted, and absorbed by surfaces. In a room with walls and objects, sound is always bouncing around and interfering, forming a noisy soundtrack that we hardly notice. Sound-absorbing materials take it out of the equation. However, even the most absorbent materials still reflect some sound, so the substance—fiberglass in this case—is shaped and arranged into deep grooves, so that sound reflected by one baffle is directed toward another. Any sound from inside the room that reaches the walls finds itself trapped in a forest of muffling structures, bouncing around endlessly and getting softer each time.</p>
<p>When all of that noise is gone, your ears start to feel a little weird. You start to hear your stomach gurgle, your watch tick, and your bones creak. You become the noise. Some visitors say it “resets” the brain.</p>
<p>There’s not a lot of research on how silence affects people. It’s a subject that Orfield finds fascinating and is exploring among his other interests, but the room is actually designed for product testing. The chamber is a scientific tool in which the Orfield and his staff can measure the properties of the sound products produce—from sleep apnea machines to heart valves, warning tones, and dashboard switches—so that manufactures can optimize their products for users.</p>
<p>I walked in the door of Orfield Labs thinking that the chamber, one of the quietest places on Earth, was the story. After spending a few hours with Orfield, though, I realized that the chamber is a good story, but not THE story. The story of this place isn’t about silence, it’s about people. It’s about the relationship between people and the spaces, sounds, and sights that surround them. That’s the real context behind the pioneering work being done at Orfield Labs.</p>

<p className="originalPostDate">Originally published on Physics Buzz, July 31, 2018. Read part two of “Becoming the Noise” on Physics Buzz: <a href = "http://physicsbuzz.physicscentral.com/2018/08/becoming-noise-part-ii-putting-humans.html" target="_blank" className="endArticleRef">Putting Humans into the Physics Equation.</a></p>

    </div>

};

export default Feature2;
