import React from 'react';

// IMAGES
import Feature3Pic from '../pictures/Features/Feature3.png';

const Feature3 = () => {
  return <div className ="mainDiv content">
  <div className="featureImg">
  <img  src={Feature3Pic} alt="" width="300" height="300"/>
  <p>A one-day data sample showing the network of pro-ISIS groups on VKontakte during January 2015. The white dots are individual users and the red and green circles indicate the particular pieces of gel (i.e. online group) that these individuals form. Red gels feature the most violent narratives. Credit: P. Manrique, M. Zheng, Z. Cao, E. M. Restrepo, and N. F. Johnson.</p>
  </div>

<p className="articleTitle">Online Hatred and Extremism: A Physicist's Take</p>
<p className="byAuthor">by Kendra Redmond</p>

<div className="mobileImg">
<img  src={Feature3Pic} alt="" width="300" height="300"/>
<p>A one-day data sample showing the network of pro-ISIS groups on VKontakte during January 2015. The white dots are individual users and the red and green circles indicate the particular pieces of gel (i.e. online group) that these individuals form. Red gels feature the most violent narratives. Credit: P. Manrique, M. Zheng, Z. Cao, E. M. Restrepo, and N. F. Johnson.</p>
</div>

<p>“Do we really have to live with this?”</p>
<p>Like many of us, that’s what Dr. Neil Johnson wondered when the news highlighted yet another seemingly random violent terrorist attack back in 2014. But unlike many of us, he and his colleagues turned to science in search of an answer.</p>
<p>“[I]t always sounds to me from the news that violent attacks by individuals come from out of the blue in a random way—and that despite subsequent months of investigation, the motive never really becomes clear,” Johnson says. “Hence the perceived wisdom is that we all just have to live with it. We decided to examine whether this is true.”</p>
<p>Johnson, a physicist at The George Washington University, specializes in complex systems. He studies collective behaviors that emerge from many small interactions over time—think of the traffic patterns that emerge from the interactions of individual cars, importiations in the financial markets that emerge from individual transactions, or the brain activity that emerges from individual neuron signals. </p>
<p>From this vantage point, Johnson wondered if extremism/hate was also a collective behavior, one that emerged from the complex system of human interactions taking place on social media. As he explains it, milk doesn’t spoil because of a few ‘bad’ molecules and superconductivity doesn’t result from a few ‘bad’ electrons. Maybe violent extremist behavior doesn’t result from a few ‘bad’ people either—maybe it develops organically from the system as a whole.</p>
<p>This isn’t just another way of looking at the problem, it’s potentially another way of looking for solutions. So, in 2014, Johnson assembled a team at his then-institution, the University of Miami, to explore how extremism and violent hate develop on social media. The results, published last week in the American Physical Society’s journal Physical Review Letters, have implications for both science and national security.</p>
<p>Before getting into the details of their research, it helps to consider a surprisingly analogous situation—curdling milk. All milk contains bacteria that feed on the milk’s lactose, producing lactic acid. This acid builds up over time. In response to the growing acidity, protein molecules in milk that once repelled each other begin to attract and form small clumps. Over time, clumps increase in number and size until suddenly large clumps are visible to the naked eye, warranting a trip to the store for fresh milk. </p>

<p>When molecules in a liquid link up such a way that they start acting like a solid, scientists call it a gel. This is one case in which the scientific definition of a word aligns with the household definition. Hair gel, shave gel, grape jelly, and Jell-O are all gels to scientists too. The way that they form, the gelation process, can be modeled using mathematical equations rooted in gelation theory.</p>
<p>The team speculated that online extremism/hate might be another kind of gel. To find out, they analyzed the formation and growth of pro-ISIS and anti-coalition (anti-U.S.) online groups on VKontakte, a Facebook-like site based in Russia that bears the distinction of being Europe's largest social media platform. At the end of 2014 and into 2015, there was a sudden growth in the number of added links (similar to “follows” on Facebook) of such extremist/hate groups. While site moderators eventually started shutting down these groups, it took them a few weeks to recognize the scope of the problem, so the number of links in those weeks reflects organic activity—a valuable data set.</p>
<p>There’s one key difference between milk proteins and humans (okay, more than one, but one that is especially relevant here). Unlike milk proteins, people are different—we have different backgrounds, religions, and experiences—and these differences impact how easily we “gel” with one another. People that are more similar are more likely to connect online. As a first step, the researchers generalized the equations of traditional gelation theory to account for this.</p>
<p>Next, the researchers compared the extremism/hate activity on VKontakte during this period to models based on the generalized gelation theory. “When we did this, we found excellent agreement with what we observed online,” recalls Johnson. “In fact, we couldn’t believe it at first: the growth of the gels (pockets of extremism/hate online) seemed to follow our mathematical theory almost exactly. Eureka moment!”</p>
<p>In other words, he explains, extremism/hate develops through the formation of small gels. </p>
<p>Each gel is a self-contained social media group that develops organically, like a curd of milk, and can be described by the physics equations of generalized gelation theory. These groups form around different ideals and intentions. However, over time these gels grow in number and size until suddenly, large clumps appear—indicating potentially dangerous levels of connection between different extremism/hate groups.</p>
<p>What does this mean in practical terms?</p>
<p>Most social media-based approaches to studying terrorist activity focus on identifying the people likely to commit such acts. This is a difficult, needle-in-a-haystack search that also brings up complicated ethical issues (reminiscent of the 2002 sci-fi movie Minority Report). However, interviews conducted with convicted terrorists demonstrate that there is a close tie between online connectivity and the intent to carry out terror-like activities: Online groups play a key role in building narratives, recruitment, and financing for such actions.</p>
<p>Instead of focusing on defeating online extremism/hate by searching for a few “bad” ringleaders, says Johnson, it may be more effective to disrupt the source of support—the online gelling process. What this looks like in practice isn’t exactly clear yet, but that’s the next goal. “[N]ow that we have the correct equations, we can use them to analyze how to deal with, and disrupt, this gel formation in the future,” Johnson says.</p>

<p className="originalPostDate">Originally published on Physics Buzz, August 1, 2018. The research paper featured was published in Physical Review Letters: <a className ="endArticleRef" href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.121.048301" target="_blank">Generalized Gelation Theory Describes Onset of Online Extremist Support.</a></p>


    </div>

};

export default Feature3;
