import React from 'react';

// IMAGES
import Feature4Pic from '../pictures/Features/Feature4.png';

const Feature4 = () => {
  return <div className='mainDiv content'>
  <div className="featureImg">
  <img  src={Feature4Pic} alt="" width="300" height="300"/>
  <p className="">This false-color map of a random light field includes a large number of a feature called C points. The colors reflect the electric field's polarization, and the circles and triangles—visible when you zoom in—mark C points, where polarization is undefined. Credit: L. De Angelis, F. Alpeggiani, and L. Kuipers.</p>
  </div>

  <p className="articleTitle">The Surprising Behavior of "Whirlpools" of Light</p>
  <p className="byAuthor">by Kendra Redmond</p>

  <div className="mobileImg">
  <img  src={Feature4Pic} alt="" width="300" height="300"/>
  <p className="">This false-color map of a random light field includes a large number of a feature called C points. The colors reflect the electric field's polarization, and the circles and triangles—visible when you zoom in—mark C points, where polarization is undefined. Credit: L. De Angelis, F. Alpeggiani, and L. Kuipers.</p>
  </div>

  <p>The fastest timescales. The highest pressures. Absolute zero. The nanoscale. These conditions are far from our everyday experience, but studying how things behave in different situations can reveal a more complete picture of their nature—and can lead to revolutionary breakthroughs.</p>
  <p>In research published last week in the American Physical Society’s journal Physical Review X, scientists from Delft University of Technology share the surprising things they found when, using a powerful microscope, they probed tiny structures in light waves confined to move in a two-dimensional world. Their work adds to our understanding of light and its properties at the nanoscale, and has applications in the growing area of light-based computer chip technologies.</p>
  <p>Imagine you’re in a well-lit room. Sunlight streams in through the windows, an overhead lightbulb shines brightly, and a desk lamp gives off a more focused beam. Look closely and you’ll see that some parts of the room are brighter than others. That’s because the brightness depends how the light waves spread out from the source and interact with each other, the room, and its contents.</p>
  <p>Just as each point in the room has a brightness that depends on the collective amount of light there, each point also has an electric field value that corresponds to the collective light there. That’s because light is an electromagnetic wave, oscillating from positive to negative as it moves through space—overlapping, adding up, and cancelling out with other light waves.</p>
  <p>If you could shrink the room down, so that the objects in it were roughly the same size as the light waves, an interesting thing would happen to the electric field in that space. At some points in the room, the electric field would take on the form of whirlpool-like structures with different shapes and properties. These shapes and their relative locations reveal information about the properties and behavior of light.</p>
  <p>At some special points, the whirlpools would be perfectly circular. These are called C points and they mark singularities in a key property of light—its polarization.</p>
  <p>In this new research, Lorenzo De Angelis, Filippo Alpeggiani, and Kobus Kuipers explored C points in a situation where light is confined to move in just two dimensions, along a surface—the team essentially collapsed our room into a flat square. Then, using a custom-built microscope that zoomed way in and scanned the area just 20 nanometers above the surface, they mapped the properties of the electric field in that space.</p>
  <p>From these properties they determined the polarization of the light at each point on the surface and identified the C points. They took special note of the index of each C point, a property that, like electrical charge, can have a positive or negative value. Like positively and negatively charged particles, similar indexes repel and opposites attract.</p>

  <p>But an analysis of the C points in the team’s two-dimensional space revealed a surprise—similar indexes did not repel like they do in three-dimensional space. Instead, C points with the same index clustered together, bunching in pairs.</p>
  <p>This finding didn’t match predictions by the commonly accepted theoretical model of the situation, so the team took a closer look at the model. On doing so, they realized that the model was based on an incorrect assumption—one that only holds when light has three dimensions to move around in. So, they went back to the beginning and created a new theoretical model of C points in two dimensions. The predictions by this new model turned out to be a great match to the experimental results, an encouraging sign of its validity.</p>
  <p>It might seem frivolous to study the nanoscale structures of light confined to two dimensions—like something too far removed from daily life to be worthwhile. But consider this: Light is absolutely fundamental to our universe, and understanding its nature means knowing more about our world. The similarities between the behavior of C points and charged particles could point to a deeper insight on the nature of charge and its relationship to light. And, on a related note, the more we know about the behavior of light across different scales and in different conditions, the better equipped we are to harness its power for the advancement of society.</p>

  <p className="originalPostDate">Originally published on Physics Buzz, October 23, 2018. The researcher paper featured was published in Physical Review X: <a href="https://journals.aps.org/prx/abstract/10.1103/PhysRevX.8.041012" className="endArticleRef" targt = "_blank">Spatial Bunching of Same-Index Polarization Singularities in Two-Dimensional Random Vector Waves.</a></p>

    </div>

};

export default Feature4;
