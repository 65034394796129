import React from 'react';

// IMAGES
import Feature1Pic from '../pictures/Features/Feature1.jpg';

const Feature1 = () => {
  return <div className="mainDiv content">
  <div className="featureImg">
  <img  src={Feature1Pic} alt="" width="300" height="300"/>
  <p>Conceptual rendering of a plasmonic aerosol. Credit: Jake Fontana and Robert Gates.</p>
  </div>
<p className="articleTitle">Lab-Created Nano Aerosols Could Improve Climate Models</p>

<p className="byAuthor">by Kendra Redmond</p>
<div className="mobileImg">
<img  src={Feature1Pic} alt="" width="300" height="300"/>
<p>Conceptual rendering of a plasmonic aerosol. Credit: Jake Fontana and Robert Gates.</p>
</div>
<p>“Climate change will affect nearly every person on the planet in the coming decades,” according to Jake Fontana, a research physicist at the U.S. Naval Research Laboratory (NRL). Our ability to reliably predict and reasonably prepare for that change depends on how well we can model the climate. Thanks to a new tool developed by Fontana and his team at NRL, more accurate models may be on the way. Their results are reported in a recent issue of the American Physical Society’s journal Physical Review B.</p>
<p>At first glance, clouds might seem like accessories—items that simply compliment current weather conditions. But that’s not the case. Clouds are intricately connected to local weather conditions and the large-scale climate. In fact, Fontana says that the largest uncertainty in models of the climate and extreme weather events is related to how clouds interact with aerosols, tiny particles in the atmosphere that come primarily from volcanic eruptions, desert dust storms, and burning coal and oil.</p>
<p>Aerosols can seed cloud formation. Recent studies also suggest that high concentrations of aerosols can intensify thunderstorms and that nano-sized aerosols can strengthen cloud convection. Larger aerosols, around 0.001 mm, have been thoroughly studied, but the interactions between nano aerosols (0.000001 mm) and clouds are complicated and have been hard to unravel despite their requireance.</p>
<p>The most logical way to study these interactions is in well-controlled lab experiments. This requires nano aerosols—individual nanoparticles suspended a gas (to model our atmosphere)—and a way to monitor the “atmospheric” conditions throughout the experiments.</p>
<p>In theory, that’s no problem. There’s a whole category of nanoparticles ideal for monitoring, called plasmonic nanoparticles (PNs). PNs interact strongly with light in fascinating but predictable ways. This makes them easy to detect and good indicators of environmental changes. Gold nanoparticles are a prime example.</p>
<p>In practice, things haven’t gone so well. “The ability to aerosolize nanoparticles and optically detect them in the laboratory has been an issue facing scientists in the field for decades,” says Fontana.</p>
<p>PNs are so sensitive to light that they scatter, absorb, and reflect light differently depending on their size, shape, and concentration. A clump of identical PNs has different optical properties than an individual. This sensitivity is what makes them so valuable, but it can also cause endless frustration. Put simply, scientists haven’t been able to suspended individual PNs in a gas.</p>
<p>Undeterred, Fontana and his team members Jeffrey Geldmeier, Paul Johns, Nicholas Greybush, and Jawad Naciri took on the challenge and developed what they called the Nanoparticle aeRsoL (NRL) instrument. The NRL instrument works by filing a small reservoir with a liquid containing gold nanorods. When fast-moving air flows over the reservoir, very small drops of liquid are pulled into the air stream, each containing one nanorod. The drops then pass through a dehumidifier that evaporates the liquid. Then, a set of mirrors bounce a probe beam through the resulting gas so that the researchers can examine its properties.</p>
<p>The team’s results revealed a stable distribution of individual nanorods in the gas—no clumps! In addition, the researchers determined that by simply changing their aspect ratio, you can make the nanorods especially sensitive to light from visible to mid-infrared frequencies. This enables very small changes in the host gas to be detected.</p>
<p>The real power of this system is that PNs can be atmospheric catalysts and monitors in the same experiment—making them very versatile tools.</p>
<p>After decades of challenges, we may finally have the tools for conducting experiments with nano-sized aerosols, experiments whose results could inform climate models and give us more accurate predictions. As we face a future with increasingly more extreme weather events, this could be invaluable.</p>
<p>But the possibilities don’t end with climate models. The researchers point out that such aerosols have potential in wide-ranging applications from next-generation optical technologies to nanomedicine and remote sensing. Perhaps someday, other types of nanoparticles could even be deployed as a form of climate engineering.</p>

<p className="originalPostDate">Originally published on Physics Buzz, May 02, 2019. The research paper featured was published in Physical Review B: <a className="endArticleRef" href="https://journals.aps.org/prb/abstract/10.1103/PhysRevB.99.081112" target="blank">Plasmonic aerosols.</a></p></div>

  };

  export default Feature1;
