import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// IMAGES
import HeadShot from '../pictures/headshot.jpg';

const About = () => {
return(
<div className="mainDiv content">
<p className="aboutTitle">Kendra Redmond</p>
<div className="flex-container-about">
  <div className="item-description">
<p>Kendra Redmond is a freelance science writer and editor. After earning a master’s degree in physics, she spent 10 years in the Washington-DC area coordinating national science education and outreach programs. Although she has written about science for years, in 2015 she left her day job to focus exclusively on this passion. She contributes regularly to <i>Physics Buzz, The SPS Observer, Radiations</i>, and <i>The MRS Bulletin</i>. Kendra now lives in Bloomington, MN with her husband and three kids. You can browse her latest stories on Facebook and review her professional experience on LinkedIn.</p>

  <div className="connect">
  <p className ="contactLabel"><b>Contact:</b> <a className="emailHyperAbout" id="mailto" href="mailto:KRedmondStories@gmail.com" target="_blank">KRedmondStories@gmail.com</a></p>
      <b className="connectLabel">Connect: </b>
      <a href="https://www.facebook.com/KRStories/" target="_blank">
        <i className="icon fab fa-facebook-square fa-4x"></i>
      </a>
      <a href="https://www.linkedin.com/in/krstories/" target="_blank">
        <i className="icon fab fa-linkedin fa-4x"></i>
      </a>
    </div>
  <p className="educationLabel"><b>Education: </b></p>
  <p className="education">M.S. in Physics, University of Wisconsin-Madison</p>
  <p className="education">B.A. in Physics, Carthage College, Kenosha, WI</p>
  </div>
  <div className="item-photo">
    <img className="headShotImg" src={HeadShot} alt="headShot"/>
  </div>
</div>
</div>

);
}

export default About;
